export default {
  server: 'wss://router.equery.cherg.net/',
  another_server: 'https://s1.main.ru.cherg.net/',
  servid: '738',
  servname: 'Тестовый сервер',
  wait_time: { // конфиг таймаутов
    current_queue: 1000, // текущая очередь
    current_client: 1000,
    deferred_queue: 5000,
    waiting_list: 5000,
    count_message: 0,
    message: 0,
    reg_list: 0,
    server_time: 1000,
    auto_time: 1000,
    beep_tab: 1000,
    intercom_list: 0
  },
  registration_tab: true,
  waiting_tab: true,
  graf_tab: true,
  plan_time: true,
  viber_chat: true,
  intercom_chat: true,
  lang: 'ru',
  additional_services_time: [
    {
      _name: '5 минут',
      _time: '300'
    },
    {
      _name: '10 минут',
      _time: '600'
    },
    {
      _name: '15 минут',
      _time: '900'
    },
    {
      _name: '20 минут',
      _time: '1200'
    }
  ]
}
